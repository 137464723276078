import React from "react"
import Layout from "../components/layout"
import PageHeader from "../components/PageHeader"
import { Whitepaper, whitepaper_data } from "../components/WhitepaperBox"
class SecondPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      videoNo: false,
      compact: false,
    }
  }
  openModal = videoNo => {
    this.setState({ videoNo })
  }
  closeModal = index => {
    this.setState({ videoNo: false })
  }
  toggle=(action)=> {
    this.setState({
      compact: !!action,
    })
  }

  render() {
    return (
      <Layout>
        <PageHeader title="Whitepaper" subtitle="Whitepapers by VoerEir" />
        <section className="section-padding-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mobile-margin-top">
                <div className="col-limit-3">
                  <div className="heading-top"></div>
                  <h2 className="heading-2 mb-5">VoerEir whitepapers </h2>
                </div>
              </div>
            </div>
              <div className={`main-media ${this.state.compact ? "compact" : ""}`}>
              {whitepaper_data.map((o, i) => (
                < Whitepaper key={i} {...o} />
              ))}
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default SecondPage
