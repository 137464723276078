import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getFileName } from "../utils"
import { RightArrow } from "../components/SVGIIcon"

export const Whitepaper = ({
  imgSrc = "media_placeholder.jpg",
  heading = "-",
  description = "",
  link = ""
}) => {
  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            fluid(maxWidth: 1920) {
              originalImg
            }
          }
        }
      }
    }
  `)

  data.allImageSharp.edges.forEach(element => {
    if (getFileName(element.node.fluid.originalImg) === imgSrc) {
      imgSrc = element.node.fluid.originalImg
    }
  })
  return (
    <div className="media-box">
        <div
          className="main-img"
          style={{ backgroundImage: `url(${imgSrc})` }}
        ></div>
        <div className="content">
          <h4>{heading}</h4>
          <p>{description}</p>
          <div className="links">
            <a className="box-label line-below" rel="noopener noreferrer" href = {link} target="_blank">
              Download PDF <RightArrow />
              </a>
          </div>
        </div>
      </div>

  )
}

export default Whitepaper

export const whitepaper_data = [
  {
    heading: "Intel & VoerEir Whitepaper on 'Network testing and DUT saturation'",
    description:
            "VoerEir published a white paper in collaboration with Intel on network testing and DUT saturation. " +
            "This white paper demonstrates the packet generation capabilities in tests on Intel® Xeon® " +
            "Scalable processor servers using Touchstone, which saturates two 25 Gbps network interface " +
            "cards while using minimal compute resources, with 64-byte packets.",
    imgSrc: "news_intel_voereir_whitepaper.png",
    link: "https://drive.google.com/file/d/1ZxBqnQ0cZLjk3-MIoyFn_PMzktIEsGUE/view?usp=sharing"
  },
  {
    heading: "Jio and VoerEir together published a whitepaper on 'Critical NFVI KPIs to validate'",
    description:
      "Jio and VoerEir have during the past year cooperated around defining a list of NFVI- KPIs which determine the performance of VNFs deployed on top of that NFVI. This white paper describes our way of reasoning when creating the list and gives examples of detailed definitions for different kinds of KPIs.",
    imgSrc: "news_jio_kpi.png",
    link: "https://drive.google.com/file/d/1WQUIn1alOFGQjhjK3STj5PDpH5egG3xW/view?usp=sharing"
  }
]
